<template>
  <div>
    <div class="row">
      <div class="flex xs12 sm4">
        <va-card class="mb-4" color="#3417ab99">
          <p class="display-2 mb-0" style="color: white">
            {{ this.lastRefresh }}
          </p>
          <p>Last Refresh</p>
        </va-card>
      </div>
      <div class="flex xs12 sm4">
        <va-card class="mb-4" color="#174dab99">
          <p class="display-2 mb-0" style="color: white">
            {{ this.chartsDataCopy?.totalUsersMatches }}
          </p>
          <p>Total Users with Credentials</p>
        </va-card>
      </div>
      <div class="flex xs12 sm4">
        <va-card class="mb-4" color="#174dab99">
          <p class="display-2 mb-0" style="color: white">
            {{ this.chartsDataCopy?.AppexInnovationsUsers }}
          </p>
          <p>Apex Innovations Users</p>
        </va-card>
      </div>
    </div>
    <div class="row row-equal">
      <div class="flex xs12 md6 xl6">
        <va-card title="Demonstration Time">
          <va-button
            icon="fa fa-spinner"
            outline
            small
            slot="actions"
            class="mr-0"
            v-if="!loading"
            @click="refresh()"
          >
            Refresh Data
          </va-button>
          <va-chart
            class="chart chart--donut"
            :data="donutData"
            type="donut"
            v-if="!loading"
          />
          <div v-if="loading" class="text-center">
            <i class="fa fa-spinner fa-spin fa-3x fa-fw" />
            <p>{{ donutProgress }}% Loaded</p>
          </div>
        </va-card>
      </div>
      <div class="flex xs12 xl6">
        <va-card title="Tests Completed">
          <va-button
            icon="fa fa-spinner"
            outline
            small
            v-if="!loading"
            slot="actions"
            class="mr-0"
            @click="refresh()"
          >
            Refresh Data
          </va-button>
          <div v-if="loading" class="text-center">
            <i class="fa fa-spinner fa-spin fa-3x fa-fw" />
            <p>{{ progress }}% Loaded</p>
          </div>
          <va-chart
            v-else
            class="chart"
            :data="credentialExamData"
            type="horizontal-bar"
          />
        </va-card>
      </div>

      <div class="flex xs12 md12 xl12">
        <va-card>
          <va-data-table :fields="fields" :data="filteredData">
            <template slot="actions" slot-scope="props">
              <va-button @click="showByCredential"
                >Tests by Credential</va-button
              >
            </template>
          </va-data-table>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import moment from "moment";
import axios from "@/scripts/interceptor.js";
export default {
  name: "credentialCharts",
  data() {
    return {
      lineChartFirstMonthIndex: 0,
      credentialsNameData: {},
      credentialExamData: {},
      tableData: [],
      donutData: {},
      loading: true,
      loadingUsers: true,
      progress: 0,
      donutProgress: 0,
      lastRateRefresh: "",
      lastRefresh: "01/01/2024, 00:00",
      chartsDataCopy: {
        totalUsersMatches: 0,
        AppexInnovationsUsers: 0,
      },
      credentialExamData: {
        labels: [],
        datasets: [],
      },
      donutData: {
        labels: [" Less than 60 Seconds", " Greater than 60 Seconds"],
        datasets: [
          {
            label: "Population (millions)",
            backgroundColor: ["rgba(23,77,171,0.6)", "rgba(52,23,171,0.6)"],
            data: [],
          },
        ],
      },
    };
  },
  computed: {
    filteredData() {
      console.log(this.chartsDataCopy);
      //format this.chartsDataCopy.reportStartAt and 
      let formatedStartAt = this.chartsDataCopy.reportStartAt ? moment(this.chartsDataCopy.reportStartAt).format("MM/DD/YYYY") : "Not Found";
      let formatedEndBefore = this.chartsDataCopy.reportEndBefore ? moment(this.chartsDataCopy.reportEndBefore).format("MM/DD/YYYY") : "Not Found";

      return [
        {
          AppexInnovationsUsers: `${this.chartsDataCopy.AppexInnovationsUsers} Users`,
          totalUsersMatches: `${this.chartsDataCopy.totalUsersMatches} Users`,
          reportStartAt: formatedStartAt,
          reportEndBefore: formatedEndBefore,
        },
      ];
    },
    fields() {
      return [
        {
          name: "AppexInnovationsUsers",
          title: "Apex Innovations Users",
          sortField: "AppexInnovationsUsers",
          width: "13%",
        },
        {
          name: "totalUsersMatches",
          title: "Total Users with Credentials",
          sortField: "totalUsersMatches",
          width: "13%",
        },
        {
          name: "reportStartAt",
          title: "Report Started At",
          sortField: "reportStartAt",
          width: "16%",
        },
        {
          name: "reportEndBefore",
          title: "Report Ended At",
          sortField: "reportEndBefore",
          width: "16%",
        },
        {
          name: "__slot:actions",
          title: "Actions",
          width: "20%",
        },
      ];
    },
  },
  methods: {
    showByCredential() {
      //sweet alert showing per credential data, for each credential show list like MD: 2, PD:2
      let credentials = this.credentialsNameData.docs.map((credential) => {
        return {
          code: credential.data().value,
          displayName: credential.data().displayName,
        };
      });
      let perCredential =
        this.chartsDataCopy.data || this.chartsDataCopy.perCredential;
      let dataAvailableLabels = perCredential ? Object.keys(perCredential) : [];
      let dataAvailableValues = perCredential
        ? Object.values(perCredential)
        : [];
      let labels = [];
      let labelsFromData = dataAvailableLabels.map((label) => {
        let credential = credentials.find(
          (credential) => credential?.code === label
        ) || { displayName: label };
        return credential?.displayName || label;
      });
      labels = labelsFromData;
      let data = labels.map((label, index) => {
        return `${label}: ${dataAvailableValues[index]}`;
      });
      if (data.length === 0) {
        data.push("No data available");
      }
      this.$swal.fire({
        title: "Tests by Credential",
        html: data.join("<br>"),
      });
    },
    formatDate(date){
      return `${date}-01`;
    },
    refresh() {
      this.$swal({
        title: "Credential Test Report",
        html: `
          <label for="swal-input1" style="display: block; float: left; margin-top: 5px; margin-right: 5px; margin-bottom: 0px;">Start Date:</label>
          <input id="swal-input1" value="${new Date()
            .toISOString()
            .slice(
              0,
              7
            )}" style="margin-top: 0.5em!important;" class="swal2-input" placeholder="Start Date" type="month" required>

          <label for="swal-input2" style="display: block; float: left; margin-top: 5px; margin-right: 5px; margin-bottom: 0px;">End Before:</label>
          <input id="swal-input2" value="${new Date()
            .toISOString()
            .slice(
              0,
              7
            )}" style="margin-top: 0.5em!important;" class="swal2-input" placeholder="End Before" type="month" required>
        `,
        focusConfirm: false,
        confirmButtonText: "Create",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        preConfirm: () => {
          const startDate = new Date(
            document.getElementById("swal-input1").value
          );
          const endDate = new Date(
            document.getElementById("swal-input2").value
          );

          const diffInMonths =
            (endDate.getFullYear() - startDate.getFullYear()) * 12 +
            (endDate.getMonth() - startDate.getMonth());

          if (diffInMonths > 24) {
            this.$swal.showValidationMessage(
              "The difference between the dates should be less or equal to 24 months"
            );
            return false;
          }

          //dont allow startDate to be greater than endDate
          if (startDate > endDate) {
            this.$swal.showValidationMessage(
              "Start Date should be less than End Date"
            );
            return false;
          }

          return [
            document.getElementById("swal-input1").value,
            document.getElementById("swal-input2").value,
          ];
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          if(result.value[0] === "" || result.value[1] === ""){
            this.$swal.fire({
              title: "Error",
              text: "Please fill in all the fields",
              icon: "error",
            });
            return;
          }
          let startAt = this.formatDate(result.value[0]);
          let endBefore = this.formatDate(result.value[1]);
          //check if any of the inputs are empty
          try {
            this.loading = true;
            //swal loading
            this.$swal.fire({
              title: "Refreshing Data",
              html: "Please wait a few minutes while we refresh the data",
              allowOutsideClick: false,
              showConfirmButton: false,
              onBeforeOpen: () => {
                this.$swal.showLoading();
              },
            });
            axios
              .post("/api/admin/CredentialTestReports", {
                startAt,
                endBefore,
              })
              .then((response) => {
                this.$swal.fire({
                  title: "Success",
                  text: "Data has been refreshed",
                  icon: "success",
                });
                this.credentialExamData = this.createDataset(
                  response?.data,
                  this.credentialsNameData
                );
                this.updateDougnutChart(response?.data);
                let seconds = response?.data?.lastRefresh?._seconds || 0;
                this.lastRefresh = moment
                  .unix(seconds)
                  .format("MM/DD/YYYY, hh:mm");
                //Internal users
                this.chartsDataCopy.AppexInnovationsUsers =
                  response?.data?.AppexInnovationsUsers;
                //Total count
                this.chartsDataCopy.totalUsersMatches =
                  response?.data?.totalUsersMatches;
                //Timestamps
                if (response?.data?.reportStartAt) {
                  this.chartsDataCopy.reportStartAt =
                    response?.data?.reportStartAt;
                }
                if (response?.data?.reportEndBefore) {
                  this.chartsDataCopy.reportEndBefore =
                    response?.data?.reportEndBefore;
                }
                //credentials
                this.chartsDataCopy.data = response?.data?.data;
                this.loading = false;
              })
              .catch((error) => {
                console.log(error);
                this.$swal.fire({
                  title: "Error",
                  text: "Something went wrong while refreshing data",
                  icon: "error",
                });
                this.loading = false;
              });
          } catch (error) {
            this.$swal({
              icon: "error",
              title: error.message,
              html: "Oops, something went wrong! Please try again.",
              showConfirmButton: true,
              timer: 1500,
            });
            return;
          }
        }
      });
    },
    createDataset(data, credentials) {
      let perCredential = data?.data || data?.perCredential;
      //find in cfrdentials the ones with value = credential and get the displayname
      credentials = credentials.docs.map((credential) => {
        return {
          code: credential.data().value,
          displayName: credential.data().displayName,
        };
      });
      let dataAvailableLabels = perCredential ? Object.keys(perCredential) : [];
      let dataAvailableValues = perCredential
        ? Object.values(perCredential)
        : [];
      let datasets = [];
      let labels = [];

      let labelsFromData = dataAvailableLabels.map((label) => {
        let credential = credentials.find(
          (credential) => credential?.code === label
        ) || { displayName: label };
        return credential?.displayName || label;
      });
      labels = labelsFromData;
      datasets.push({
        label: "Tests Completed",
        backgroundColor: "rgba(23,77,171,0.6)",
        borderColor: "transparent",
        data: dataAvailableValues,
      });
      return {
        labels,
        datasets,
      };
    },
    updateDougnutChart(chartsData) {
      this.donutData.datasets[0].data = [
        chartsData?.DemostrationUsers?.LessThan60,
        chartsData?.DemostrationUsers?.MoreThan60,
      ];
      if (chartsData.donutData?.hasOwnProperty("lastRefresh")) {
        if (
          chartsData.donutData?.lastRefresh instanceof
          firebase.firestore.Timestamp
        ) {
          this.lastRateRefresh = ` - [${moment(
            chartsData.donutData?.lastRefresh.toDate()
          ).format("MM/DD/YYYY, hh:mm")}]`;
        } else {
          this.lastRateRefresh = "Not Found";
        }
      } else {
        this.lastRateRefresh = "Not Found";
      }
      this.donutProgress += 70;
      this.progress += 70;
      //PASS/FAIL CHART
      this.loadingUsers = this.progress !== 100;
      this.loading = this.donutProgress !== 100;
    },
  },
  async mounted() {
    try {
      this.donutProgress = 30;
      this.progress = 30;

      let chartsData = await firebase
        .firestore()
        .collection("charts")
        .doc("credentials")
        .get();

      this.credentialsNameData = await firebase
        .firestore()
        .collection("credentials")
        .get();
      chartsData = chartsData.data();
      // USER & EXAMS CHART
      this.chartsDataCopy = chartsData;
      this.credentialExamData = this.createDataset(
        chartsData,
        this.credentialsNameData
      );
      if (chartsData.hasOwnProperty("lastRefresh")) {
        if (chartsData.lastRefresh instanceof firebase.firestore.Timestamp) {
          this.lastRefresh = `${moment(chartsData?.lastRefresh.toDate()).format(
            "MM/DD/YYYY, hh:mm"
          )}`;
        } else {
          this.lastRefresh = "Not Found";
        }
      } else {
        this.lastRefresh = "Not Found";
      }
      //PASS/FAIL CHART
      this.updateDougnutChart(chartsData);
    } catch (error) {
      console.log(error);
      this.$swal.fire({
        title: "Error",
        text: "Something went wrong while fetching data",
        icon: "error",
      });
      this.loading = false;
      this.loadingUsers = false;
    }
  },
};
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
